import { store } from '~/plugins/vuex';

export default defineNuxtRouteMiddleware(async () => {
  const { user } = store.state.auth;
  const isLoggedIn = !!user;

  if (isLoggedIn) {
    return await navigateTo('/');
  }
});
